<app-arivo-toast></app-arivo-toast>
<router-outlet />
@if (showCookieDialog) {
  <div class="cookie-dialog-container">
    <div class="card card-small">
      <div class="card-body">
        <div class="body-medium mb-1" [innerText]="'Cookies.CookieConsentIntroduction' | translate"></div>
        <div class="body-medium mb-1" [innerText]="'Cookies.CookieConsentDescription' | translate"></div>

        <div class="flex flex-wrap justify-end gap-2 mt-3">
          <button
            type="button"
            class="button button-primary-text button-small"
            [innerText]="'Cookies.RejectAll' | translate"
            (click)="rejectNonEssentialCookies()"
          ></button>
          <button
            type="submit"
            class="button button-primary button-small"
            [innerText]="'Cookies.AcceptCookies' | translate"
            (click)="acceptAllCookies()"
          ></button>
        </div>
      </div>
    </div>
  </div>
}
