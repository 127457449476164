// src/app/shared/components/arivo-dialog-container/dialog-config.ts

import { TranslateService } from '@ngx-translate/core';
import { Dialog } from '../../services/dialog.service';
import { FeatureFlagService } from '../../../core/services/feature-flag.service';

export function createDialogConfig(translateService: TranslateService, featureFlagService: FeatureFlagService) {
  return {
    getPaymentMethodReminderDialog(): Dialog {
      let content =
        translateService.instant('Dialog.PaymentMethodMissing.Content') + ' ' + translateService.instant('Dialog.GenericBanWarning');
      if (featureFlagService.getFeatureFlag('enable_contract_creation_dashboard')) {
        content += '<br/>' + translateService.instant('Dialog.NoContractsWithoutPaymentMethodWarning');
      }

      return {
        title: translateService.instant('Dialog.PaymentMethodMissing.Title'),
        subtitle: translateService.instant('Dialog.PaymentMethodMissing.Subtitle'),
        content,
        dismissible: true,
        buttonText: translateService.instant('Dialog.PaymentMethodMissing.ButtonText'),
        buttonRoute: { link: '/billing', queryParams: { showPaymentForm: 'true' } },
        type: 'error',
      };
    },

    getCustomerAddressReminderDialog(): Dialog {
      let content =
        translateService.instant('Dialog.CustomerAddressMissing.Content') + ' ' + translateService.instant('Dialog.GenericBanWarning');
      if (featureFlagService.getFeatureFlag('enable_contract_creation_dashboard')) {
        content += '<br/>' + translateService.instant('Dialog.NoContractsWithoutPaymentMethodWarning');
      }

      return {
        title: translateService.instant('Dialog.CustomerAddressMissing.Title'),
        subtitle: translateService.instant('Dialog.CustomerAddressMissing.Subtitle'),
        content,
        dismissible: true,
        buttonText: translateService.instant('Dialog.CustomerAddressMissing.ButtonText'),
        buttonRoute: { link: '/data' },
        type: 'error',
      };
    },

    getFinishRegistrationReminderDialog(): Dialog {
      let content =
        translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.Content') +
        ' ' +
        translateService.instant('Dialog.GenericBanWarning');
      if (featureFlagService.getFeatureFlag('enable_contract_creation_dashboard')) {
        content += '<br/>' + translateService.instant('Dialog.NoContractsWithoutPaymentMethodWarning');
      }

      return {
        title: translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.Title'),
        subtitle: translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.Subtitle'),
        content,
        dismissible: true,
        buttonText: translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.ButtonText'),
        buttonRoute: { link: '/onboarding/finish' },
        type: 'error',
      };
    },

    getPaymentFailedDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.PaymentFailed.Title'),
        subtitle: translateService.instant('Dialog.PaymentFailed.Subtitle'),
        content: translateService.instant('Dialog.PaymentFailed.Content'),
        buttonText: translateService.instant('Dialog.PaymentFailed.ButtonText'),
        buttonRoute: { link: '/billing', queryParams: { showPaymentForm: 'true' } },
        type: 'error',
      };
    },

    getBanImminentDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.BanImminent.Title'),
        subtitle: translateService.instant('Dialog.BanImminent.Subtitle'),
        content: translateService.instant('Dialog.BanImminent.Content'),
        buttonText: translateService.instant('Dialog.BanImminent.ButtonText'),
        buttonRoute: { link: '/billing', queryParams: { showPaymentForm: 'true' } },
        type: 'error',
      };
    },

    getBannedDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.Banned.Title'),
        content: translateService.instant('Dialog.Banned.Content'),
        buttonText: translateService.instant('Dialog.Banned.ButtonText'),
        type: 'error',
      };
    },

    getMissingPaymentMethodBannedDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.MissingPaymentMethod.Title'),
        subtitle: translateService.instant('Dialog.MissingPaymentMethod.Subtitle'),
        content: translateService.instant('Dialog.MissingPaymentMethod.Content'),
        buttonText: translateService.instant('Dialog.MissingPaymentMethod.ButtonText'),
        buttonRoute: { link: '/billing', queryParams: { showPaymentForm: 'true' } },
        type: 'error',
      };
    },

    getWelcomeDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.Welcome.Title'),
        content: translateService.instant('Dialog.Welcome.Content'),
        dismissible: true,
        dismissButtonText: translateService.instant('Dialog.Welcome.Dismiss'),
        type: 'info',
      };
    },

    getAccountCompletionDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.WelcomeFinished.Title'),
        content: translateService.instant('Dialog.WelcomeFinished.Content'),
        dismissible: true,
        dismissButtonText: translateService.instant('Dialog.WelcomeFinished.Dismiss'),
        type: 'info',
      };
    },
  };
}
