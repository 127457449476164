import { Component, OnInit } from '@angular/core';
import { Language } from '../../../core/utils/common.data';
import { LanguageService } from '../../../core/services/language.service';
import { ProviderSettingsService } from '../../../core/services/provider-settings.service';
import { ProviderContactData } from '../../../shared/models/providerSettings';

@Component({
  selector: 'app-customer-service-info',
  templateUrl: './customer-service-info.component.html',
  styleUrl: './customer-service-info.component.scss',
})
export class CustomerServiceInfoComponent implements OnInit {
  contactData!: ProviderContactData;

  constructor(
    private languageService: LanguageService,
    private providerSettingsService: ProviderSettingsService,
  ) {}

  ngOnInit() {
    try {
      this.contactData = this.providerSettingsService.getContactData();
    } catch (error) {
      // Handle the error appropriately
    }
  }

  getCurrentLanguage(): string {
    return this.languageService.getCurrentLanguage();
  }

  changeLanguage(language: Language): void {
    this.languageService.setLanguage(language);
  }
}
