import { Pipe, PipeTransform } from '@angular/core';
import { ProviderSettingsService } from '../services/provider-settings.service';
import { ImageOption, ProviderImageType } from '../../shared/models/providerSettings';
import { UrlPipe } from './url.pipe';

@Pipe({
  name: 'providerImage',
})
export class ProviderImagePipe implements PipeTransform {
  constructor(
    private providerSettingsService: ProviderSettingsService,
    private urlPipe: UrlPipe,
  ) {}

  transform(value: ProviderImageType, returnType: 'url' | 'altText' = 'url'): string | undefined {
    const imageOption: ImageOption | undefined = this.providerSettingsService.getProviderImage(value);
    if (!imageOption) {
      return undefined;
    }
    if (returnType === 'url') {
      if (imageOption.url?.startsWith('/assets') || imageOption.url?.startsWith('assets')) {
        return this.urlPipe.transform(imageOption.url);
      }
      return imageOption.url;
    }

    if (!imageOption.alt_text || imageOption.alt_text === '') {
      if (value === 'logo' || value === 'logo_large') {
        return 'Logo';
      } else if (value === 'favicon') {
        return 'Favicon';
      } else if (value === 'pay_per_use_logo') {
        return 'Pay per use logo';
      }
    } else {
      return imageOption.alt_text;
    }

    return undefined;
  }
}
