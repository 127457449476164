<div class="headline-small mb-2 md:mb-4" [innerText]="'Help.HelpAndContact' | translate"></div>

<div class="card card-horizontal">
  <div class="card-body" *ngIf="contactData">
    <div>
      <div class="body-large text-decoration-dark mb-5" [innerText]="'Help.AccessCustomerSupport' | translate"></div>
      <div class="mb-4">
        <div class="body-medium text-decoration-dark mb-2" [innerText]="'Help.CustomerSupport' | translate"></div>
        <div class="flex items-center gap-2 mb-2 text-interaction">
          <i class="fa-kit fa-phone"></i>
          <p class="body-medium">
            <a [href]="'tel:' + contactData.phone" class="hover:text-interaction-light text-interaction no-underline">
              {{ contactData.phone }}</a
            >
          </p>
        </div>
        <div class="flex items-center gap-2 text-interaction">
          <i class="fa-kit fa-mail"></i>
          <p class="body-medium">
            <a [href]="'mailto:' + contactData.email" class="hover:text-interaction-light text-interaction no-underline">
              {{ contactData.email }}
            </a>
          </p>
        </div>
      </div>
      <p
        *ngIf="contactData.website"
        class="body-medium"
        [innerHtml]="'Help.FurtherInformation' | translate: { url: contactData.website }"
      ></p>
    </div>
  </div>
  <div class="card-body">
    <div class="body-large text-decoration-dark mb-5" [innerText]="'Help.UserManualHeader' | translate"></div>

    <div class="flex items-center gap-2 text-interaction body-large">
      <i class="fa-light fa-book-heart text-3xl"></i>
      <p>
        <a
          href="https://docs.parken.arivo.app/kunden/serviceportal_neu/"
          rel="noopener noreferrer"
          target="_blank"
          class="hover:text-interaction-light text-interaction no-underline"
          [title]="'Help.UserManual' | translate"
          [innerText]="'Help.UserManual' | translate"
        >
        </a>
      </p>
    </div>
  </div>
</div>
