<div class="link pt-4 px-4 gap-2">
  @if (isInternalLink(providerLinks.privacy_policy.url)) {
    <a
      [routerLink]="providerLinks.privacy_policy.url"
      [title]="'Sidebar.DataPrivacy' | translate"
      [target]="hasToken() ? '_self' : '_blank'"
      [innerText]="'Sidebar.DataPrivacy' | translate"
    >
    </a>
  } @else {
    <a
      [href]="providerLinks.privacy_policy.url"
      target="_blank"
      rel="noopener noreferrer"
      [title]="'Sidebar.DataPrivacy' | translate"
      [innerText]="'Sidebar.DataPrivacy' | translate"
    >
    </a>
  }

  @if (isInternalLink(providerLinks.terms_and_conditions.url)) {
    <a
      [routerLink]="providerLinks.terms_and_conditions.url"
      [title]="'Sidebar.TermsAndConditions' | translate"
      [target]="hasToken() ? '_self' : '_blank'"
      [innerText]="'Sidebar.TermsAndConditions' | translate"
    >
    </a>
  } @else {
    <a
      [href]="providerLinks.terms_and_conditions.url"
      target="_blank"
      rel="noopener noreferrer"
      [title]="'Sidebar.TermsAndConditions' | translate"
      [innerText]="'Sidebar.TermsAndConditions' | translate"
    >
    </a>
  }

  @if (isInternalLink(providerLinks.imprint.url)) {
    <a
      [routerLink]="providerLinks.imprint.url"
      [title]="'Sidebar.Imprint' | translate"
      [innerText]="'Sidebar.Imprint' | translate"
      [target]="hasToken() ? '_self' : '_blank'"
    >
    </a>
  } @else {
    <a
      [href]="providerLinks.imprint.url"
      rel="noopener noreferrer"
      [title]="'Sidebar.Imprint' | translate"
      [innerText]="'Sidebar.Imprint' | translate"
    >
    </a>
  }

  @if (providerLinks.arbitration_board) {
    <a
      [href]="providerLinks.arbitration_board.url"
      target="_blank"
      rel="noopener noreferrer"
      [title]="'Sidebar.ArbitrationBoard' | translate"
      [innerText]="'Sidebar.ArbitrationBoard' | translate"
    >
    </a>
  }

  @if (providerLinks.accessibility) {
    <a
      [href]="providerLinks.accessibility.url"
      target="_blank"
      rel="noopener noreferrer"
      [title]="'Sidebar.Accessibility' | translate"
      [innerText]="'Sidebar.Accessibility' | translate"
    >
    </a>
  }
</div>
