@if (garage) {
  <!-- Mobile -->
  <div class="block md:hidden text-center mb-4">
    <div class="headline-small mb-1" [innerText]="garage.name"></div>
    <div class="body-large">
      <span class="text-decoration-dark" [innerText]="formatAddress(garage)"></span>
    </div>
  </div>

  <!-- Desktop -->
  <div class="hidden md:block">
    <div class="headline-x-small mb-2">
      <span [innerText]="garage.name"></span>
      <span class="font-light">&nbsp;&nbsp;</span>
      <span class="font-light" [innerText]="formatAddress(garage)"></span>
    </div>
    <hr class="mb-4" />
  </div>

  <div class="flex flex-col lg:flex-row gap-4">
    <div class="lg:flex-shrink-0" [ngClass]="showWiparkShortTermParking ? 'lg:w-[50%]' : 'lg:w-full'">
      <div class="body-large mb-0.5">
        <div class="text-decoration-dark">
          <i class="fa-kit fa-contract mr-1.5"></i>
          <span class="font-medium" [innerText]="'Locations.Contracts' | translate"></span>
        </div>
      </div>
      <div class="body-small mb-2">
        <span class="text-neutral-50" [innerText]="'Locations.LongTermParkingDescription' | translate"></span>
      </div>
      @if (bookableContractTemplates.length > 0) {
        <div class="flex flex-col gap-2">
          @for (contractTemplate of bookableContractTemplates; track contractTemplate) {
            <div
              class="card card-small"
              [ngClass]="{
                'hover:bg-decoration-transparent-4 cursor-pointer': creatingContractEnabled,
              }"
              [routerLink]="creatingContractEnabled ? '/contracts/create' : null"
              [queryParams]="{ garageId: garage!.id, productId: contractTemplate.id }"
            >
              <div class="card-body flex items-center justify-between gap-4 group">
                <div class="flex-shrink overflow-hidden text-left">
                  <div class="body-medium overflow-hidden whitespace-nowrap overflow-ellipsis mb-0.5">
                    <span class="font-medium text-decoration-dark" [innerText]="contractTemplate.name"></span>
                  </div>
                  <div class="body-medium overflow-hidden whitespace-nowrap overflow-ellipsis" *ngIf="contractTemplate.description">
                    <span class="text-neutral-30" [innerText]="contractTemplate.description"></span>
                  </div>
                </div>
                <div class="flex items-center gap-4">
                  <div class="text-right">
                    <div class="body-medium text-interaction mb-0.5">
                      <span
                        class="font-medium"
                        [innerText]="contractTemplate.price.amount | localizedCurrency: contractTemplate.price.currency"
                      ></span>
                    </div>
                    <div class="body-medium" [innerText]="'Common.Monthly' | translate"></div>
                  </div>
                  @if (creatingContractEnabled) {
                    <div>
                      <i class="fa-kit fa-contract-new text-2xl text-interaction-dark group-hover:text-interaction"></i>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      }

      @if (informationContractTemplates.length > 0) {
        <div class="body-large text-decoration-dark mt-4" [innerText]="'Contracts.NotBookableOnline' | translate"></div>
        <div class="body-small mt-0.5 mb-2">
          <span class="text-neutral-50" [innerText]="'Contracts.ContactUsToBookAdditionalContracts' | translate"></span>
        </div>
        <div class="flex flex-col gap-2">
          @for (contractTemplate of informationContractTemplates; track contractTemplate) {
            <div class="card card-small bg-surface-70">
              <div class="card-body flex items-center justify-between gap-4 group">
                <div class="flex-shrink overflow-hidden text-left">
                  <div class="body-medium overflow-hidden whitespace-nowrap overflow-ellipsis mb-0.5">
                    <span class="font-medium text-decoration-dark" [innerText]="contractTemplate.name"></span>
                  </div>
                  <div class="body-medium overflow-hidden whitespace-nowrap overflow-ellipsis" *ngIf="contractTemplate.description">
                    <span class="text-neutral-30" [innerText]="contractTemplate.description"></span>
                  </div>
                </div>
                <div class="flex items-center gap-4">
                  <div class="text-right">
                    <div class="body-medium text-interaction mb-0.5">
                      <span
                        class="font-medium"
                        [innerText]="contractTemplate.price.amount | localizedCurrency: contractTemplate.price.currency"
                      ></span>
                    </div>
                    <div class="body-medium" [innerText]="'Common.Monthly' | translate"></div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }

      @if (bookableContractTemplates.length > 0 || informationContractTemplates.length > 0) {
        <div class="body-medium mt-4" *ngIf="!creatingContractEnabled">
          <span class="text-neutral-50" [innerHtml]="'Contracts.ContractCreationDisabledInfo' | translate"></span>
        </div>
      } @else {
        <div class="body-medium text-neutral-50 text-center py-4" [innerText]="'Locations.NoContractsAvailable' | translate"></div>
      }
    </div>
    @if (showWiparkShortTermParking) {
      <hr class="my-2 block lg:hidden" />
      <div class="lg:w-[50%] lg:flex-shrink-0">
        <div class="body-large mb-0.5">
          <div class="text-decoration-dark mb-0.5">
            <i class="fa-kit fa-time mr-1.5"></i>
            <span class="font-medium" [innerText]="'Locations.TimeBasedParking' | translate"></span>
            <span class="font-medium">:&nbsp;</span>
            <span class="font-light" [innerText]="'Locations.ShortTermParking' | translate"></span>
          </div>
        </div>
        <div class="body-small mb-2">
          <span class="text-neutral-50" [innerText]="'Locations.ShortTermParkingDescription' | translate"></span>
        </div>
        <div class="card card-small">
          <div class="card-body">
            <table class="price-table">
              <th></th>
              <tr>
                <td class="body-medium align-top">
                  <span class="font-medium text-decoration-dark" [innerText]="'Locations.Tariffs.Wipark.ZoneOne' | translate"></span>
                </td>
              </tr>
              <tr>
                <td class="body-medium align-top">
                  <span class="text-neutral-30" [innerText]="'Locations.Tariffs.Wipark.HourlyRate' | translate"></span>
                </td>
                <td class="body-medium text-right align-top">
                  <span class="font-medium text-interaction whitespace-nowrap" [innerText]="3.9 | localizedCurrency: 'EUR'"></span>
                </td>
              </tr>
              <tr>
                <td class="body-medium align-top">
                  <span class="text-neutral-30" [innerText]="'Locations.Tariffs.Wipark.EveningFlatRate' | translate"></span>
                </td>
                <td class="body-medium text-right align-top">
                  <span class="font-medium text-interaction whitespace-nowrap" [innerText]="7.9 | localizedCurrency: 'EUR'"></span>
                </td>
              </tr>
              <tr>
                <td class="body-medium align-top">
                  <span class="font-medium text-decoration-dark" [innerText]="'Locations.Tariffs.Wipark.ZoneTwo' | translate"></span>
                </td>
              </tr>
              <tr>
                <td class="body-medium align-top">
                  <span class="text-neutral-30" [innerText]="'Locations.Tariffs.Wipark.HourlyRate' | translate"></span>
                </td>
                <td class="body-medium text-right align-top">
                  <span class="font-medium text-interaction whitespace-nowrap" [innerText]="2.9 | localizedCurrency: 'EUR'"></span>
                </td>
              </tr>
              <tr>
                <td class="body-medium align-top">
                  <span class="text-neutral-30" [innerText]="'Locations.Tariffs.Wipark.EveningFlatRate' | translate"></span>
                </td>
                <td class="body-medium text-right align-top">
                  <span class="font-medium text-interaction whitespace-nowrap" [innerText]="5.9 | localizedCurrency: 'EUR'"></span>
                </td>
              </tr>
              <tr>
                <td class="body-medium align-top">
                  <span class="font-medium text-decoration-dark" [innerText]="'Locations.Tariffs.Wipark.ZoneThree' | translate"></span>
                </td>
              </tr>
              <tr>
                <td class="body-medium align-top">
                  <span class="text-neutral-30" [innerText]="'Locations.Tariffs.Wipark.HourlyRate' | translate"></span>
                </td>
                <td class="body-medium text-right align-top">
                  <span class="font-medium text-interaction whitespace-nowrap" [innerText]="1.9 | localizedCurrency: 'EUR'"></span>
                </td>
              </tr>
              <tr>
                <td class="body-medium align-top">
                  <span class="text-neutral-30" [innerText]="'Locations.Tariffs.Wipark.EveningFlatRate' | translate"></span>
                </td>
                <td class="body-medium text-right align-top">
                  <span class="font-medium text-interaction whitespace-nowrap" [innerText]="3.9 | localizedCurrency: 'EUR'"></span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    }
  </div>
}
