import { Component, OnInit } from '@angular/core';
import { ProviderLinkOptions } from '../../models/providerSettings';
import { ProviderSettingsService } from '../../../core/services/provider-settings.service';
import { StorageManagerService } from '../../../core/services/storage-manager.service';

@Component({
  selector: 'arivo-legal-links',
  templateUrl: './arivo-legal-links.component.html',
  styleUrl: './arivo-legal-links.component.scss',
})
export class ArivoLegalLinksComponent implements OnInit {
  providerLinks!: ProviderLinkOptions;

  constructor(
    private providerSettingsService: ProviderSettingsService,
    private storageManagerService: StorageManagerService,
  ) {}

  ngOnInit() {
    this.providerSettingsService.providerSettings.subscribe(() => {
      this.providerLinks = this.providerSettingsService.getLinks();
    });
  }

  isInternalLink(url: string): boolean {
    return url.startsWith('/');
  }

  hasToken(): boolean {
    return !!this.storageManagerService.load('token');
  }
}
