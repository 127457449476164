import { Injectable } from '@angular/core';
import { LicensePlate } from '../../../shared/models/licensePlate';
import { SignupParkingSession } from '../../../shared/models/parkingSession';
import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LPRCountryCode } from '../../../shared/models/country';
import { FeatureFlagService } from '../../../core/services/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  licensePlate: BehaviorSubject<LicensePlate | null> = new BehaviorSubject<LicensePlate | null>(null);
  onboardingSession: BehaviorSubject<SignupParkingSession | null> = new BehaviorSubject<SignupParkingSession | null>(null);

  constructor(
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
  ) {}

  getOnboardingSession(licensePlate: LicensePlate): Observable<SignupParkingSession> {
    return this.http
      .get<SignupParkingSession>(
        `/api/onegate/v2/oss/signup_search/?license_plate=${licensePlate.license_plate}&license_plate_country=${licensePlate.license_plate_country}`,
      )
      .pipe(
        tap((res) => {
          this.licensePlate.next(licensePlate);
          this.onboardingSession.next(res);
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  sendOnboardingEmail(licensePlate: LicensePlate, email: string, marketingConsent?: boolean): Observable<any> {
    let request: { email: string; license_plate: string; license_plate_country: LPRCountryCode; marketing_consent?: boolean } = {
      email: email,
      license_plate: licensePlate.license_plate,
      license_plate_country: licensePlate.license_plate_country,
    };

    if (this.featureFlagService.getFeatureFlag('enable_marketing_consent')) {
      request.marketing_consent = marketingConsent;
    }

    return this.http.post(`/api/user/v2/auth/register/email/`, request);
  }
}
