import { environment } from '../../../environments/environment';
import { ProviderSettings } from '../models/providerSettings';

export const defaultProviderSettings: ProviderSettings = {
  website_title: environment.website_title,
  general_data: {
    name: 'Example Company Austria',
    address: 'Example Street 1',
    city: 'Vienna',
    zip_code: '1010',
    country: 'Austria',
    contact_phone_number: '+436605555555',
    contact_email: 'example@mail.co',
    vat_id: 'ATU12345678',
    jurisdiction: 'Handelsgericht Wien',
    register_number: 'FN123456a',
    representative: 'Example Respresentative',
  },
  images: {
    logo: { url: '/assets/brands/arivo.svg', alt_text: 'Arivo Logo' },
    logo_large: { url: '/assets/brands/arivo.svg', alt_text: 'Arivo Logo' },
    favicon: { url: '/favicon.ico', alt_text: 'Arivo Favicon' },
    pay_per_use_logo: undefined,
  },
  links: {
    terms_and_conditions: { url: '/terms-and-conditions' },
    privacy_policy: { url: '/data-privacy' },
    imprint: { url: '/imprint' },
    accessibility: undefined,
    arbitration_board: undefined,
  },
  feature_flags: {
    enable_marketing_consent: environment.featureFlags.enable_marketing_consent,
    enable_contract_creation_dashboard: environment.featureFlags.enable_contract_creation_dashboard,
    enable_contract_creation_products: environment.featureFlags.enable_contract_creation_products,
    enable_contract_termination: environment.featureFlags.enable_contract_termination,
    enable_contract_qr_code: environment.featureFlags.enable_contract_qr_code,
    enable_access_card: environment.featureFlags.enable_access_card,
    enable_app_and_notifications: environment.featureFlags.enable_app_and_notifications,
    enable_cookie_consent: environment.featureFlags.enable_cookie_consent,
  },
  developer_feature_flags: {
    enable_demo_login: environment.developmentFlags.enable_demo_login,
    enable_demo_login_only: environment.developmentFlags.enable_demo_login_only,
    enable_mock_calls: environment.developmentFlags.enable_mock_calls,
  },
};
