import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProviderTranslateService } from '../../../../core/services/provider-translate.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-dev-menu',
  templateUrl: './dev-menu.component.html',
  styleUrls: ['./dev-menu.component.scss'],
})
export class DevMenuComponent implements OnInit {
  environmentForm!: FormGroup;
  providerLanguageKey = environment.translation_key ?? 'default';

  constructor(private providerTranslateService: ProviderTranslateService) {}

  ngOnInit(): void {
    this.environmentForm = new FormGroup({
      selectedEnvironment: new FormControl(this.providerLanguageKey),
    });

    this.environmentForm.get('selectedEnvironment')?.valueChanges.subscribe((value) => {
      environment.translation_key = value;
      this.providerTranslateService.devSwitchProviderKey(value);
    });
  }
}
