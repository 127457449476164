import { Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { LicensePlate, PayPerUseLicensePlate } from '../../../shared/models/licensePlate';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { requireLicensePlate } from '../../../shared/utils/form.utils';
import { ArivoLicensePlateInputComponent } from '../../../shared/components/ui-components/arivo-license-plate-input/arivo-license-plate-input.component';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { DashboardResolve } from '../../../shared/models/routeTyping';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DashboardApiService } from '../dashboard-api.service';
import { formatLicensePlate } from '../../../shared/utils/license-plate.utils';
import { showSpinner } from '../../../shared/utils/common.utils';
import { ToastService } from '../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreDataService } from '../../../core/services/core-data.service';

@Component({
  selector: 'app-dashboard-pay-per-use',
  templateUrl: './dashboard-pay-per-use.component.html',
  styleUrl: './dashboard-pay-per-use.component.scss',
})
export class DashboardPayPerUseComponent implements OnInit {
  @ViewChild(ArivoLicensePlateInputComponent) licensePlateInputComponent!: ArivoLicensePlateInputComponent;
  private _destroyRef = inject(DestroyRef);

  get showLicensePlateForm(): boolean {
    return this._showLicensePlateForm;
  }

  set showLicensePlateForm(value: boolean) {
    this._showLicensePlateForm = value;
    this.licensePlateForm.reset();
  }
  private _showLicensePlateForm: boolean = false;

  licensePlates: PayPerUseLicensePlate[] = [];

  garageAmount: number = 0;

  constructor(
    private _formBuilder: FormBuilder,
    @Inject(ActivatedRoute) private route: TypedRoute<DashboardResolve>,
    private dashboardApiService: DashboardApiService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private coreDataService: CoreDataService,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => {
      this.licensePlates = data.dashboardApiResponse.pay_per_use_license_plates;
      this.garageAmount = data.dashboardApiResponse.pay_per_use_garage_count;
    });
    this.licensePlateForm.controls.licensePlate.addValidators(this.uniqueLicensePlate.bind(this));
  }

  get enabledLicensePlates(): number {
    return this.licensePlates.filter((licensePlate) => licensePlate.pay_per_use_enabled).length;
  }

  licensePlateForm = this._formBuilder.nonNullable.group({
    licensePlate: [<LicensePlate>{}, [requireLicensePlate, this.uniqueLicensePlate.bind(this)]],
  });

  onPayPerUseCheckboxClicked(licensePlate: PayPerUseLicensePlate): void {
    this.dashboardApiService
      .togglePayPerUseLicensePlate({ ...licensePlate, pay_per_use_enabled: !licensePlate.pay_per_use_enabled })
      .pipe(showSpinner())
      .subscribe({
        next: (updatedPlate) => {
          this.licensePlates = this.licensePlates.map((plate) => {
            if (plate.license_plate === licensePlate.license_plate && plate.license_plate_country === licensePlate.license_plate_country) {
              return updatedPlate;
            }
            return plate;
          });
          this.toastService.add({
            message: this.translateService.instant(
              updatedPlate.pay_per_use_enabled ? 'Dashboard.AddedPayPerUseToast' : 'Dashboard.RemovedPayPerUseToast',
              {
                licensePlate: formatLicensePlate(updatedPlate.license_plate, updatedPlate.license_plate_country),
              },
            ),
            type: 'success',
          });
          this.setHasPayPerUseLicensePlateCoreData();
        },
        error: (error) => {
          //TODO: refactor error handling
          if (error.status === 400 && (error.error.detail || error.error.non_field_errors)) {
            const detail = error.error.detail;
            if (detail) {
              for (const key in detail) {
                if (detail.hasOwnProperty(key)) {
                  detail[key].forEach((message: string) => {
                    this.toastService.add({
                      message: message,
                      type: 'error',
                    });
                  });
                }
              }
            }
            const nonFieldErrors = error.error.non_field_errors;
            if (nonFieldErrors) {
              for (const key in nonFieldErrors) {
                if (nonFieldErrors.hasOwnProperty(key)) {
                  nonFieldErrors[key].forEach((message: string) => {
                    this.toastService.add({
                      message: message,
                      type: 'error',
                    });
                  });
                }
              }
            }
          } else {
            this.toastService.add({ message: this.translateService.instant('Common.Errors.UnknownError'), type: 'error' });
          }
        },
      });
  }

  uniqueLicensePlate(control: AbstractControl) {
    if (
      this.licensePlates.some((licensePlate) => {
        return (
          licensePlate.license_plate_country === control.value.license_plate_country &&
          licensePlate.license_plate === control.value.license_plate
        );
      })
    ) {
      return { unique: true };
    }
    return null;
  }

  addLicensePlate(): void {
    this.licensePlateInputComponent.markAllAsTouched();
    if (this.licensePlateForm.valid) {
      this.dashboardApiService
        .addPayPerUseLicensePlate(this.licensePlateForm.value.licensePlate!)
        .pipe(showSpinner())
        .subscribe({
          next: (newPayPerUsePlate: PayPerUseLicensePlate) => {
            this.licensePlates.push(newPayPerUsePlate);
            this.showLicensePlateForm = false;
            this.licensePlateForm.reset();
            this.setHasPayPerUseLicensePlateCoreData();
            this.toastService.add({
              message: this.translateService.instant(
                newPayPerUsePlate.pay_per_use_enabled ? 'Dashboard.AddedPayPerUseToast' : 'Dashboard.AddedButCouldNotActivatePayPerUse',
                {
                  licensePlate: formatLicensePlate(newPayPerUsePlate.license_plate, newPayPerUsePlate.license_plate_country),
                },
              ),
              type: newPayPerUsePlate.pay_per_use_enabled ? 'success' : 'warning',
            });
          },
          error: (error) => {
            if (error.error.detail?.non_field_errors) {
              this.toastService.add({ message: error.error.detail.non_field_errors[0], type: 'error' });
            } else {
              this.toastService.add({ message: this.translateService.instant('Common.Errors.UnknownError'), type: 'error' });
            }
          },
        });
    }
  }

  setHasPayPerUseLicensePlateCoreData() {
    this.coreDataService.setEnabledPayPerUseLicensePlateStatus(this.enabledLicensePlates > 0);
  }

  protected readonly formatLicensePlate = formatLicensePlate;
}
