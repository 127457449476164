<div class="bg-blue-100 p-4 rounded-xl">
  <div class="body-large mb-1">Dev Menu</div>
  <div [formGroup]="environmentForm">
    <div class="input-primary-container">
      <select id="environment-select" class="input-primary" formControlName="selectedEnvironment">
        <option value="default">default</option>
        <option value="wipark">wipark</option>
      </select>
      <label for="environment-select" [innerText]="'Provider Language'"></label>
    </div>
  </div>
</div>
