<div class="well-responsive well-inverted">
  <div class="flex flex-col items-center justify-center h-52">
    <div class="mb-8 flex flex-col items-center" *ngIf="contactData">
      <div class="body-medium mb-2" [innerText]="'Help.CustomerSupport' | translate"></div>
      <div class="flex gap-2 mb-2 text-interaction bg">
        <i class="fa-kit fa-phone"></i>
        <p class="body-medium">
          <a [href]="'tel:' + contactData.phone" class="hover:text-interaction-light text-interaction no-underline">{{
            contactData.phone
          }}</a>
        </p>
      </div>
      <div class="flex gap-2 text-interaction">
        <i class="fa-kit fa-mail"></i>
        <p class="body-medium">
          <a [href]="'mailto:' + contactData.email" class="hover:text-interaction-light text-interaction no-underline">{{
            contactData.email
          }}</a>
        </p>
      </div>
    </div>
  </div>
  <div class="footer-languages flex flex-row items-center justify-center mt-2.5">
    <a (click)="changeLanguage('de')" [class.active]="getCurrentLanguage() === 'de'">Deutsch</a>
    <div class="mx-1">|</div>
    <a (click)="changeLanguage('en')" [class.active]="getCurrentLanguage() === 'en'">English</a>
  </div>
</div>
