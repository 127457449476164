import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StorageManagerService } from '../services/storage-manager.service';
import { ToastService } from '../../shared/services/toast.service';
import { VerifySignupJWTResponse } from '../../shared/models/routeTyping';
import { parseDate } from '../../shared/utils/common.utils';
import { TranslateService } from '@ngx-translate/core';
import { OnboardingService } from '../../features/signup/onboarding/onboarding.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  loggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private http: HttpClient,
    private storeManager: StorageManagerService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private onboardingService: OnboardingService,
  ) {}

  logIn(token: string, destination?: string) {
    this.storeManager.store('token', token);
    this.loggedIn.next(true);
    if (destination) {
      if (destination.startsWith('/onboarding/finish')) {
        this.storeManager.store('userInitialLogin', 'true');
      }
      this.router.navigateByUrl(destination);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  async logOut() {
    this.storeManager.delete('token');
    this.storeManager.delete('userInitialLogin');
    this.loggedIn.next(false);
    await this.router.navigate(['/login']);
  }

  verifyLoginJWT(token: string): Observable<{ key: string }> {
    return this.http.post<{ key: string }>(`/api/user/v2/auth/login/verify/`, {
      token,
    });
  }

  verifySignupJWT(token: string): Observable<VerifySignupJWTResponse> {
    return this.http
      .post<{ key: string; parking_session: any; transfer_error: boolean }>(`/api/user/v2/auth/register/verify/`, {
        token,
      })
      .pipe(map((x) => this.deserializeSignupJWTResponse(x)));
  }

  deserializeSignupJWTResponse(response: any): VerifySignupJWTResponse {
    let deserializedResponse: VerifySignupJWTResponse = {
      key: response.key,
      transfer_error: response.transfer_error,
      parking_session: null,
    };
    if (response.parking_session) {
      deserializedResponse.parking_session = {
        entry_time: parseDate(response.parking_session.entry_time),
        duration_in_seconds: response.parking_session.duration_in_seconds,
        amount_unpaid: {
          amount: parseFloat(response.parking_session.amount_unpaid.amount),
          currency: response.parking_session.amount_unpaid.currency,
        },
        license_plate: response.parking_session.license_plate,
      };
    }
    return deserializedResponse;
  }

  showTokenVerifyErrorToast(error: HttpErrorResponse) {
    let message = undefined;
    if (error.status === 401) {
      if (error.error?.error_code === 'invalid_token') {
        message = 'Common.Errors.TokenLinkInvalid';
      } else if (error.error?.error_code === 'token_expired') {
        message = 'Common.Errors.TokenLinkExpired';
      } else if (error.error?.error_code === 'token_not_found') {
        message = 'Common.Errors.TokenLinkAlreadyUsed';
      }
    }
    if (error.status === 400) {
      if (error.error?.error_code === 'license_plate_used') {
        message = 'Common.Errors.TokenLicensePLateAlreadyUsed';
      }
      if (error.error?.error_code === 'email_used') {
        message = 'Common.Errors.TokenEmailAlreadyUsed';
      }
    }
    this.toastService.add(
      {
        message: this.translateService.instant(message ?? 'Common.Errors.UnknownError'),
        type: 'error',
      },
      10000,
    );
  }
}
