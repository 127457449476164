export const environment = {
  name: 'staging',
  website_title: 'Staging Serviceportal',
  translation_key: 'default',
  production: true,
  backend_url: '',
  dev_adyen_backend_url: 'http://localhost:3000',
  featureFlags: {
    enable_marketing_consent: true,
    enable_contract_creation_dashboard: true,
    enable_contract_creation_products: true,
    enable_contract_termination: true,
    enable_contract_qr_code: true,
    enable_access_card: true,
    enable_app_and_notifications: false,
    enable_cookie_consent: true,
  },
  developmentFlags: {
    enable_demo_login: true,
    enable_demo_login_only: false,
    enable_mock_calls: true,
    enable_provider_key_switch: true,
  },
};
