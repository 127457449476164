@if (invoicePeriods.length > 0) {
  <!-- Mobile -->
  <div class="xl:hidden">
    @for (invoicePeriod of paginatedPeriods; track invoices[invoicePeriod]) {
      <arivo-accordion class="border-gray-200 border-1 mb-4 last:mb-0" [open]="startOpen">
        <arivo-accordion-header [caretLeft]="true">
          <div class="flex flex-wrap items-center justify-between body-medium w-full gap-2">
            <div>
              <div class="font-medium" [innerText]="invoicePeriod | localizedDate: 'MMMM yyyy'"></div>
            </div>
            <div class="flex-grow flex flex-col items-end gap-1">
              <div
                [ngClass]="invoices[invoicePeriod].costs.amount >= 0 ? 'text-interaction-dark' : 'text-success-dark'"
                [innerText]="invoices[invoicePeriod].costs.amount | localizedCurrency: invoices[invoicePeriod].costs.currency"
              ></div>
              @if (invoices[invoicePeriod].status) {
                <span
                  *ngIf="invoices[invoicePeriod].status === 'cleared'"
                  class="body-small pill-success"
                  [innerText]="'Billing.Invoices.cleared' | translate"
                ></span>
                <span
                  *ngIf="invoices[invoicePeriod].status === 'deducted'"
                  class="body-small pill-success"
                  [innerText]="'Billing.Invoices.deducted' | translate"
                ></span>
                <span
                  *ngIf="invoices[invoicePeriod].status === 'refunded'"
                  class="body-small pill-success"
                  [innerText]="'Billing.Invoices.refunded' | translate"
                ></span>
                <span
                  *ngIf="invoices[invoicePeriod].status === 'open'"
                  class="body-small pill-info"
                  [innerText]="'Billing.Invoices.open' | translate"
                ></span>
                <span
                  *ngIf="invoices[invoicePeriod].status === 'uncollectible'"
                  class="body-small pill-error"
                  [innerText]="'Billing.Invoices.uncollectible' | translate"
                ></span>
                <span
                  *ngIf="invoices[invoicePeriod].status === 'nonrefundable'"
                  class="body-small pill-error"
                  [innerText]="'Billing.Invoices.nonrefundable' | translate"
                ></span>
              }
            </div>
          </div>
        </arivo-accordion-header>
        <arivo-accordion-body class="parking-session-accordion-body">
          @if (invoices[invoicePeriod].invoices.length > 0) {
            <div class="flex flex-col gap-4">
              @for (invoice of invoices[invoicePeriod].invoices; track invoice.id) {
                <div class="border-1 border-decoration-transparent-12 rounded-lg overflow-hidden">
                  <div class="bg-decoration-transparent-4 p-3">
                    <div class="flex flex-wrap justify-between body-medium w-full gap-2">
                      <div>
                        <div [innerText]="invoice.invoice_number"></div>
                        <div class="mt-1 md:mt-0.5 text-neutral-50" [innerText]="invoice.invoice_date | localizedDate"></div>
                      </div>
                      <div class="flex-grow flex flex-col items-end">
                        <div class="flex items-center">
                          <div
                            class="inline-block"
                            [ngClass]="invoice.costs.amount >= 0 ? 'text-interaction-dark' : 'text-success-dark'"
                            [innerText]="invoice.costs.amount | localizedCurrency: invoice.costs.currency"
                          ></div>
                        </div>
                        <div class="text-right body-x-small mt-1.5 inline-flex">
                          <div
                            [ngClass]="{
                              'pill-success':
                                invoice.status === 'cleared' || invoice.status === 'deducted' || invoice.status === 'refunded',
                              'pill-info': invoice.status === 'open',
                              'pill-error': invoice.status === 'uncollectible' || invoice.status === 'nonrefundable',
                            }"
                            [innerText]="'Billing.Invoices.' + invoice.status | translate"
                          ></div>
                          <div
                            *ngIf="invoice.fees"
                            class="ml-2 pill-error cursor-pointer"
                            [innerText]="
                              'Billing.Invoices.FeesApplied'
                                | translate: { amount: invoice.fees.amount | localizedCurrency: invoice.fees.currency }
                            "
                            [matTooltip]="'Billing.Invoices.FeesTooltip' | translate"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="p-3">
                    <div class="flex flex-wrap gap-4 body-medium mb-4">
                      <div class="flex-1 flex-shrink-0">
                        <div class="text-neutral-50 whitespace-nowrap">
                          <i class="fa-light fa-calendar-week w-3.5 mr-1.5"></i>
                          <span [innerText]="'Billing.Invoices.InvoicePeriod' | translate"></span>
                        </div>
                        <div class="text-neutral-30 whitespace-nowrap">
                          <span [innerText]="invoice.period_start | localizedDate: 'shortDate'"></span>
                          <span class="mx-1">-</span>
                          <span [innerText]="invoice.period_end | localizedDate: 'shortDate'"></span>
                        </div>
                      </div>

                      <div class="flex-1 flex-shrink-0">
                        <div class="text-neutral-50 whitespace-nowrap">
                          <i class="fa-light fa-file-invoice-dollar w-3.5 mr-1.5"></i>
                          <span [innerText]="'Billing.Invoices.InvoiceType' | translate"></span>
                        </div>
                        @if (invoice.invoice_type !== 'reversal') {
                          <div
                            class="text-neutral-30 whitespace-nowrap"
                            [innerText]="'Dashboard.OpenParkingType.' + invoice.product_category | translate"
                          ></div>
                        } @else {
                          <div class="text-neutral-30 whitespace-nowrap" [innerText]="'Billing.Invoices.Reversal' | translate"></div>
                        }
                      </div>
                    </div>
                    <a
                      *ngIf="invoice.pdf_url"
                      class="button-primary-outlined button-small inline-flex text-decoration-none"
                      [href]="invoice.pdf_url"
                      target="_blank"
                      download
                    >
                      <i class="fa-light fa-arrow-down-to-bracket text-lg mr-2"></i>
                      <span [innerText]="'Billing.Invoices.DownloadInvoice' | translate"></span>
                    </a>
                  </div>
                </div>
              }
            </div>
          } @else {
            <div class="body-medium">
              <div class="text-center text-neutral-50" [innerText]="'Billing.Invoices.NoBillsForThisPeriod' | translate"></div>
            </div>
          }
        </arivo-accordion-body>
      </arivo-accordion>
    }
  </div>

  <!-- Desktop -->
  <div class="hidden xl:block max-w-full overflow-x-auto">
    <table class="table-primary table-expandable">
      <thead class="body-medium">
        <tr>
          <th></th>
          <th class="cursor-pointer" (click)="orderByDate()">
            <span class="font-medium" [innerText]="'Billing.Invoices.InvoiceDate' | translate"></span>
            <span class="ml-2">
              @if (orderedBy === 'date') {
                @if (orderedByOrder === 'asc') {
                  <i class="fa-light fa-arrow-down"></i>
                } @else {
                  <i class="fa-light fa-arrow-up"></i>
                }
              } @else {
                <i class="fa-light fa-arrow-down-arrow-up text-neutral-70"></i>
              }
            </span>
          </th>
          <th>
            <span class="font-medium" [innerText]="'Billing.Invoices.InvoiceNumber' | translate"></span>
          </th>
          <th>
            <span class="font-medium" [innerText]="'Billing.Invoices.InvoicePeriod' | translate"></span>
          </th>
          <th>
            <span class="font-medium" [innerText]="'Billing.Invoices.InvoiceType' | translate"></span>
          </th>
          <th>
            <span class="font-medium" [innerText]="'Billing.Invoices.Status' | translate"></span>
          </th>
          <th>
            <div class="text-right">
              <span class="font-medium" [innerText]="'Billing.Invoices.InvoiceAmount' | translate"></span>
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody class="body-medium">
        @for (invoicePeriod of paginatedPeriods; track invoices[invoicePeriod]) {
          <tr
            class="cursor-pointer"
            [ngClass]="invoices[invoicePeriod].expanded ? 'mb-3' : ''"
            [class.bg-interaction-transparent-8]="invoices[invoicePeriod].expanded"
            (click)="invoices[invoicePeriod].expanded = !invoices[invoicePeriod].expanded"
          >
            <td>
              <div class="box-content flex items-center justify-center w-3.5 h-3.5 p-1">
                @if (invoices[invoicePeriod].expanded) {
                  <i class="fa-light fa-chevron-up"></i>
                } @else {
                  <i class="fa-light fa-chevron-down"></i>
                }
              </div>
            </td>
            <td colspan="4">
              <span class="font-medium" [innerText]="invoicePeriod | localizedDate: 'MMMM yyyy'"></span>
            </td>
            <td>
              <span
                *ngIf="invoices[invoicePeriod].status"
                class="body-small"
                [ngClass]="{
                  'pill-success': ['cleared', 'deducted', 'refunded'].includes(invoices[invoicePeriod].status!),
                  'pill-info': invoices[invoicePeriod].status === 'open',
                  'pill-error': ['uncollectible', 'nonrefundable'].includes(invoices[invoicePeriod].status!),
                }"
                [innerText]="'Billing.Invoices.' + invoices[invoicePeriod].status | translate"
              ></span>
            </td>
            <td>
              <div
                class="text-right"
                [ngClass]="invoices[invoicePeriod].costs.amount >= 0 ? 'text-interaction-dark' : 'text-success-dark'"
                [innerText]="invoices[invoicePeriod].costs.amount | localizedCurrency: invoices[invoicePeriod].costs.currency"
              ></div>
            </td>
            <td></td>
          </tr>
          @if (invoices[invoicePeriod].invoices.length > 0) {
            @for (invoice of invoices[invoicePeriod].invoices; track invoice.id) {
              <tr [ngClass]="invoices[invoicePeriod].expanded ? 'bg-interaction-transparent-4' : 'hidden'">
                <td></td>
                <td [innerText]="invoice.invoice_date | localizedDate"></td>
                <td [innerText]="invoice.invoice_number"></td>
                <td class="whitespace-nowrap">
                  <span [innerText]="invoice.period_start | localizedDate: 'shortDate'"></span>
                  <span class="mx-0.5">-</span>
                  <span [innerText]="invoice.period_end | localizedDate: 'shortDate'"></span>
                </td>
                @if (invoice.invoice_type !== 'reversal') {
                  <td [innerText]="'Dashboard.OpenParkingType.' + invoice.product_category | translate"></td>
                } @else {
                  <td [innerText]="'Billing.Invoices.Reversal' | translate"></td>
                }
                <td>
                  <div class="flex flex-wrap gap-2">
                    <span
                      class="body-small"
                      [ngClass]="{
                        'pill-success': invoice.status === 'cleared' || invoice.status === 'deducted' || invoice.status === 'refunded',
                        'pill-info': invoice.status === 'open',
                        'pill-error': invoice.status === 'uncollectible' || invoice.status === 'nonrefundable',
                      }"
                      [innerText]="'Billing.Invoices.' + invoice.status | translate"
                    ></span>
                    <span
                      *ngIf="invoice.fees"
                      class="body-small pill-error cursor-pointer"
                      [innerText]="
                        'Billing.Invoices.FeesApplied'
                          | translate: { amount: invoice.fees.amount | localizedCurrency: invoice.fees.currency }
                      "
                      [matTooltip]="'Billing.Invoices.FeesTooltip' | translate"
                    ></span>
                  </div>
                </td>
                <td>
                  <div class="flex justify-end items-center">
                    <span class="flex-nowrap">
                      <div
                        [ngClass]="invoice.costs.amount >= 0 ? 'text-interaction-dark' : 'text-success-dark'"
                        [innerText]="invoice.costs.amount | localizedCurrency: invoice.costs.currency"
                      ></div>
                    </span>
                  </div>
                </td>
                <td>
                  <div class="flex justify-center" *ngIf="invoice.pdf_url">
                    <a class="button-primary-text button-small text-decoration-none" [href]="invoice.pdf_url" target="_blank" download>
                      <i class="fa-light fa-arrow-down-to-bracket"></i>
                    </a>
                  </div>
                </td>
              </tr>
            }
          } @else {
            <tr [ngClass]="invoices[invoicePeriod].expanded ? 'bg-interaction-transparent-4' : 'hidden'">
              <td colspan="8">
                <div class="text-center text-neutral-50" [innerText]="'Billing.Invoices.NoBillsForThisPeriod' | translate"></div>
              </td>
            </tr>
          }
          <div [ngClass]="{ 'mb-5': invoices[invoicePeriod].expanded }"></div>
        }
      </tbody>
    </table>
  </div>

  <div class="flex justify-end mt-4">
    <arivo-paginator #paginator [items]="invoicePeriods" (beforePageChange)="collapse(paginator.startIndex, paginator.endIndex)" />
  </div>
} @else {
  <div class="text-center my-6">
    <div class="text-neutral-50 text-2xl">
      <i class="fa-kit fa-wallet"></i>
    </div>
    <div class="body-medium mt-2">
      <span class="text-neutral-50" [innerText]="'Billing.Invoices.NoBills' | translate"></span>
    </div>
  </div>
}
