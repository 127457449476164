import { DestroyRef, inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ProviderSettingsService } from './provider-settings.service';
import { DevFeatureFlags, FeatureFlags } from '../../shared/models/providerSettings';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private _destroyRef = inject(DestroyRef);
  private featureFlags: FeatureFlags = environment.featureFlags;
  private developmentFlags: DevFeatureFlags = environment.developmentFlags;
  constructor(private providerSettingsService: ProviderSettingsService) {
    this.providerSettingsService.providerSettings.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((settings) => {
      if (settings) {
        this.setFeatureFlags(settings.feature_flags);
        if (settings.developer_feature_flags) {
          this.setDevelopmentFlags(settings.developer_feature_flags);
        }
      }
    });
  }

  private setFeatureFlags(featureFlags: FeatureFlags) {
    this.featureFlags = { ...this.featureFlags, ...featureFlags };
  }

  private setDevelopmentFlags(developmentFlags: DevFeatureFlags) {
    this.developmentFlags = { ...this.developmentFlags, ...developmentFlags };
  }

  getFeatureFlag(flag: keyof FeatureFlags): boolean {
    return this.featureFlags[flag] ?? false;
  }

  getDevelopmentFlag(flag: keyof DevFeatureFlags): boolean {
    const value = this.developmentFlags[flag];
    return value ?? false;
  }
}
