import { DestroyRef, inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { LoadingService } from './loading.service';
import { UrlPipe } from '../pipes/url.pipe';
import * as Sentry from '@sentry/angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin } from 'rxjs';

interface TranslationDictionary {
  [key: string]: string | TranslationDictionary;
}
@Injectable({
  providedIn: 'root',
})
export class ProviderTranslateService {
  private _destroyRef = inject(DestroyRef);
  private prefix = environment.translation_key ?? 'default';
  private translationsLoaded: { [lang: string]: boolean } = {};

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private urlPipe: UrlPipe,
    private loadingService: LoadingService,
  ) {}

  initProviderTranslations() {
    this.loadProviderTranslations();
    this.translateService.onLangChange.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.loadProviderTranslations(true);
    });
  }

  loadProviderTranslations(switchLang = false) {
    const currentLang = this.translateService.currentLang;
    if (this.prefix && this.prefix !== 'default' && !this.translationsLoaded[currentLang]) {
      this.loadingService.setLoading(true);
      const translationFilePath = this.urlPipe.transform(`/assets/i18n/${this.prefix}/${currentLang}.json`);

      this.http.get<TranslationDictionary>(translationFilePath).subscribe({
        next: (providerTranslations: TranslationDictionary) => {
          this.translateService.setTranslation(this.translateService.currentLang, providerTranslations, true);
          this.translationsLoaded[currentLang] = true;
          setTimeout(
            () => {
              this.loadingService.setLoading(false);
            },
            switchLang ? 500 : 0,
          );
        },
        error: (error) => {
          Sentry.captureException(error);
          setTimeout(
            () => {
              this.loadingService.setLoading(false);
            },
            switchLang ? 500 : 0,
          );
        },
      });
    }
  }

  devSwitchProviderKey(key: string) {
    this.prefix = key;
    this.translationsLoaded = {};

    this.loadingService.setLoading(true);

    const languages = ['de', 'en'];
    const translationRequests = languages.map((lang) => {
      let translationFilePath = this.urlPipe.transform(`/assets/i18n/${lang}.json`);
      if (key !== 'default') {
        translationFilePath = this.urlPipe.transform(`/assets/i18n/${this.prefix}/${lang}.json`);
      }
      return this.http.get<TranslationDictionary>(translationFilePath);
    });

    forkJoin(translationRequests).subscribe({
      next: (translations) => {
        translations.forEach((providerTranslations, index) => {
          this.translateService.setTranslation(languages[index], providerTranslations, true);
          this.translationsLoaded[languages[index]] = true;
        });
        setTimeout(() => {
          this.loadingService.setLoading(false);
        }, 500);
      },
      error: (error) => {
        Sentry.captureException(error);
        setTimeout(() => {
          this.loadingService.setLoading(false);
        }, 500);
      },
    });
  }
}
