import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';
import { branchName, commitHash } from './environments/version';

export const initializeSentry = () => {
  Sentry.init({
    enabled: environment.production,
    environment: environment.name,
    release: `${environment.name}:${branchName}@${commitHash}`,
    dsn: 'https://ecb98a62f6b153cf17f421a97d0fb018@debug.acc.si/70',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration({ failedRequestStatusCodes: [[500, 599]] }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/wipark\.digimon\.arivo\.fun\/api/, /^https:\/\/wipark\.arivo\.app\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
