import { Component, OnInit } from '@angular/core';
import { ProviderSettingsService } from '../../core/services/provider-settings.service';
import { ProviderContactData } from '../../shared/models/providerSettings';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrl: './help.component.scss',
})
export class HelpComponent implements OnInit {
  contactData: ProviderContactData = {
    phone: '',
    email: '',
  };

  constructor(private providerSettingsService: ProviderSettingsService) {}

  ngOnInit(): void {
    this.contactData = this.providerSettingsService.getContactData();
  }
}
