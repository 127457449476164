import { Component, OnInit } from '@angular/core';
import { ProviderGeneralData } from '../../../shared/models/providerSettings';
import { ProviderSettingsService } from '../../../core/services/provider-settings.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrl: './imprint.component.scss',
})
export class ImprintComponent implements OnInit {
  providerData!: ProviderGeneralData;

  constructor(private providerSettingsService: ProviderSettingsService) {}

  ngOnInit() {
    this.providerData = this.providerSettingsService.getProviderGeneralData();
  }

  isAustria(providerData: ProviderGeneralData) {
    return providerData.country === 'Austria' || providerData.country === 'Österreich';
  }

  isGermany(providerData: ProviderGeneralData) {
    return providerData.country === 'Germany' || providerData.country === 'Deutschland';
  }
}
