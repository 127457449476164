import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProviderSettingsService } from '../../../../core/services/provider-settings.service';
import { initializeSentry } from '../../../../../sentry';
import { StorageManagerService } from '../../../../core/services/storage-manager.service';
import { FeatureFlagService } from '../../../../core/services/feature-flag.service';
import { environment } from '../../../../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrl: './base-layout.component.scss',
})
export class BaseLayoutComponent implements OnInit {
  _destroyRef = inject(DestroyRef);

  showCookieDialog: boolean = false;

  constructor(
    private storageManagerService: StorageManagerService,
    private providerSettingsService: ProviderSettingsService,
    private featureFlagService: FeatureFlagService,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.providerSettingsService.providerSettings.pipe(takeUntilDestroyed(this._destroyRef)).subscribe({
      next: (providerSettings) => {
        if (providerSettings) {
          if (!providerSettings.website_title || providerSettings.website_title === '') {
            this.titleService.setTitle(environment.website_title ?? 'Arivo Serviceportal');
          } else {
            this.titleService.setTitle(providerSettings.website_title);
          }
          document.querySelector('#dynamic-favicon')?.setAttribute('href', providerSettings.images.favicon?.url ?? '/favicon.ico');
          if (this.featureFlagService.getFeatureFlag('enable_cookie_consent') && !this.storageManagerService.load('cookiePreferences')) {
            if (!this.showCookieDialog) {
              this.showCookieDialog = true;
            }
          }
        }
      },
    });
  }

  rejectNonEssentialCookies() {
    const cookiePreferences = {
      functionalCookies: false,
    };
    this.storageManagerService.store('cookiePreferences', cookiePreferences);

    this.showCookieDialog = false;
  }

  acceptAllCookies() {
    const cookiePreferences = {
      functionalCookies: true,
    };
    this.storageManagerService.store('cookiePreferences', cookiePreferences);

    this.showCookieDialog = false;

    if (cookiePreferences.functionalCookies) {
      initializeSentry();
    }
  }
}
