<div
  class="license-plate license-plate-compact"
  [class.license-plate-eu]="fullCountry.euCountry"
  [class.license-plate-compact]="compact"
  [class.friendly]="customPlate && checkInvalid"
>
  @if (fullCountry.lprCode !== 'UNKNOWN') {
    <div class="license-plate-country">
      <div class="license-plate-country-flag">
        @if (fullCountry.euCountry) {
          <span [style]="'background-image: url(' + ('/assets/flags/eu.svg' | url) + ')'" class="country-flag"></span>
        } @else {
          <span
            [style]="'background-image: url(' + ('/assets/flags/' + fullCountry.iso2Code.toLowerCase() + '.svg' | url) + ')'"
            class="country-flag"
          ></span>
        }
      </div>
      <div class="license-plate-country-name" [innerText]="country"></div>
    </div>
  }
  <div class="license-plate-number" [class.invalid]="invalidWarning" [innerText]="licensePlate?.toUpperCase()"></div>
  @if (invalidWarning || (customPlate && checkInvalid)) {
    <i
      class="warning-icon"
      [ngClass]="customPlate ? 'fa-solid fa-heart pr-2' : 'fa-light fa-triangle-exclamation pr-2'"
      [matTooltip]="customPlate ? 'This license plate belongs to a dear friend <3' : ('Tooltip.LicensePlateMaybeInvalid' | translate)"
    ></i>
  }
</div>
